/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'write': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.885 2.583a.397.397 0 01.002.56l-1.03-1.03a.397.397 0 01.56.002l.468.468zm-1.734.237l1.03 1.03.706.706.705-.705v-.002a1.395 1.395 0 000-1.972l-.468-.468a1.396 1.396 0 00-1.973-.001h-.001l-.705.706.706.706zm.366 3.146l-5.788 5.788a.124.124 0 01-.054.031l-2.727.755a.397.397 0 01-.488-.488l.755-2.727a.123.123 0 01.032-.054l6.493-6.493 1.777 1.776.706.706-.707.706zM12.74 4.19L7.116 9.814l-.41 1.48 1.48-.41L13.81 5.26l-1.07-1.07z" _fill="#B0B0B6"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.885 2.583l-.468-.468a.397.397 0 00-.56-.001l1.03 1.03a.394.394 0 00-.002-.561zm-8.59 7.335l-.303 1.09 1.09-.302 5.445-5.446-.787-.787-5.446 5.445zM12.74 4.19l1.07 1.07-5.624 5.624-1.48.41.41-1.48L12.74 4.19z" _fill="#B0B0B6"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M11.566 17.208H3.27a2.573 2.573 0 01-2.57-2.57V4.584a2.573 2.573 0 012.57-2.57h4.032a.754.754 0 110 1.508H3.27c-.587 0-1.062.476-1.063 1.062v10.054c.001.586.476 1.061 1.062 1.062h8.296m1.062-1.062v1.488-5.52a.754.754 0 111.508 0v4.032a2.573 2.573 0 01-2.57 2.57m0-1.508H3.27h8.296z" _fill="#B0B0B6"/>'
  }
})
